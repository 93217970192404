import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import FeatureList from "../components/featureList"
import QuoteForm from "../components/quoteForm"
import CallIcon from "../components/callIcon"

import InsuranceImage from "../images/stock-image-11.jpg"
import InsuranceImageMobile from "../images/stock-image-11-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-11-tablet.jpg"
import Stock1Src from "../images/stock-image-12.jpg"
import Stock2Src from "../images/stock-image-13.jpg"
import Stock3Src from "../images/stock-image-14.jpg"
import GlassesSrc from "../images/stock-image-15.jpg"

const GlassesImage = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
`

const VisionInsurance = () => {
  const { t } = useTranslation()

  const features = [
    {
      description: t("vi_f0_title"),
      href: null,
      image: Stock1Src,
      imageFirst: true,
    },
    {
      description: t("vi_f1_title"),
      href: null,
      image: Stock2Src,
      imageFirst: false,
    },
    {
      description: t("vi_f2_title"),
      href: null,
      image: Stock3Src,
      imageFirst: true,
    },
  ]

  return (
    <Layout>
      <SEO title={t("vision_insurance")} />

      <InsuranceDescription
        title={t("vision_insurance_title")}
        subtitle={t("vision_insurance_subtitle")}
        actions={
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("we_call_you")}
            </a>
          </>
        }
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <div className="bg-white py-md-6">
        <Container className="gx-0 gx-md-3">
          <FeatureList list={features} normalFontWeight />
        </Container>
      </div>

      <div className="mb-5">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col>
              <GlassesImage src={GlassesSrc} />
            </Col>
          </Row>
          <Row className="gx-0 gx-md-3 mt-4 mt-md-5">
            <Col xs="12" md="8" className="equalize-padding-x mx-auto">
              <h3 className="fw-normal">{t("vision_insurance_title_2")}</h3>
              <a
                href={`tel:${APREMIA_PHONE}`}
                className="btn btn-primary btn-sm"
              >
                <CallIcon /> {APREMIA_PHONE_FORMATTED}
              </a>
              <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
                {t("we_call_you")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default VisionInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
